import titleCase from "titlecase"

const _TAG_NAME_TO_LABEL = {
  ".net": ".net",
  cpp: "C++",
  nosql: "NoSQL",
  sql: "SQL",
  git: "git",
  css: "CSS",
  api: "API",
  ios: "iOS",
  php: "PHP",
  csharp: "C#",
}

export function tagNameToLabel(tagName) {
  if (tagName in _TAG_NAME_TO_LABEL) {
    return _TAG_NAME_TO_LABEL[tagName]
  } else {
    return titleCase(tagName)
  }
}
