import React from "react"
import { Helmet } from "react-helmet"

const SEO = ({ title, description, twitter }) => {
  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={title}
      titleTemplate="%s | Blog Surf"
      meta={[
        {
          name: `description`,
          content: description || "A directory of personal blogs.",
        },
      ]}
    >
      {twitter && (
        <meta property="twitter:card" content="summary_large_image"></meta>
      )}
      {twitter && (
        <meta
          property="twitter:title"
          content="Miami Tech Week Directory"
        ></meta>
      )}
      {twitter && (
        <meta
          property="twitter:description"
          content="Directory of people at the Miami Tech Week."
        ></meta>
      )}
      {twitter && (
        <meta
          property="twitter:image"
          content="https://i.imgur.com/9yK4XZC.png"
        ></meta>
      )}
    </Helmet>
  )
}

export default SEO
