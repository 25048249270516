import React from "react"
import { css } from "@emotion/react"
import Navbar from "./Navbar"
import SEO from "./seo"
import "./reset.css"

const Layout = ({ children }) => {
  return (
    <div
      css={css`
        font-family: "Nunito";
      `}
    >
      <SEO />
      <Navbar />
      <div>{children}</div>
    </div>
  )
}

export default Layout
