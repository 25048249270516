import React, { useState, useEffect, useRef } from "react"
import algoliasearch from "algoliasearch"
import Layout from "../components/Layout"
import { css } from "@emotion/react"
import SearchItem from "../components/SearchItem"
import SEO from "../components/seo"
import Select from "react-select"
import { tagNameToLabel } from "../utils/constants"
import PostItem from "../components/PostItem"

const searchClient = algoliasearch(
  "NLHR9ELNUE",
  "840353a9471d1e5f29702b1427d4550c"
)

const index = searchClient.initIndex("codeblogs")

const Search = ({ data }) => {
  const {
    allAirtable: { edges, totalCount },
  } = data

  const searchInputEl = useRef(null)
  const tags = new Set()
  for (let edge of edges) {
    const {
      node: { data },
    } = edge
    if (data?.["Tags"]) {
      for (let tag of data["Tags"]) {
        tags.add(tag)
      }
    }
  }
  const [hits, setHits] = useState([])
  const [searchTags, setSearchTags] = useState([])
  const [emptyResults, setEmptyResults] = useState(false)
  const [loading, setLoading] = useState(false)
  const [hasEverSearched, setHasEverSearched] = useState(false)

  const onSearchButtonClick = () => {
    setEmptyResults(false)
    setLoading(true)
    setHasEverSearched(true)
    index
      .search(searchInputEl.current.value, {
        optionalWords: searchInputEl.current.value,
        tagFilters: [searchTags.map(t => t.value)],
      })
      .then(({ hits }) => {
        setLoading(false)
        if (!hits.length) setEmptyResults(true)
        setHits(hits)
      })
  }

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        onSearchButtonClick()
      }
    }
    document.addEventListener("keydown", listener)
    return () => {
      document.removeEventListener("keydown", listener)
    }
  }, [onSearchButtonClick])

  return (
    <Layout>
      <SEO title="Search" />

      <div
        css={css`
          margin: 0 auto;
          margin-bottom: 50px;
          max-width: 700px;
        `}
      >
        <h1
          css={css`
            text-align: center;
          `}
        >
          Latest Blog Posts
        </h1>
        {edges.slice(0, 25).map((edge, i) => {
          const {
            node: { data },
          } = edge
          return <PostItem data={data} index={i} key={data["URL"]} />
        })}
      </div>
    </Layout>
  )
}

export default Search
export const query = graphql`
  query IndexSearchQuery {
    allAirtable(
      filter: {
        data: {
          Flag: { ne: true }
          RSS: { ne: "no" }
          Last_Post_Date: { ne: null }
          dkb_review: { eq: "approved" }
        }
        table: { eq: "main" }
      }
      sort: { order: DESC, fields: data___Last_Post_Timestamp }
    ) {
      totalCount
      edges {
        node {
          data {
            Name
            URL
            RSS
            Flag
            NotCodingSpecific
            Last_Post_Date
            Last_Post_Title
            Last_Post_URL
            Tags
          }
        }
      }
    }
  }
`
