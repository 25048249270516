import React from "react"
import { css } from "@emotion/react"
import { Link } from "gatsby"
import moment from "moment"
import RSSIcon from "../assets/rss.svg"
import { tagNameToLabel } from "../utils/constants"
import { OutboundLink } from "gatsby-plugin-amplitude-analytics"
function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min) //The maximum is exclusive and the minimum is inclusive
}
const ListItem = ({ data, index }) => {
  return (
    <div
      css={css`
        margin-bottom: 10px;
        padding: 5px 20px;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: baseline;
        `}
      >
        <h2
          css={css`
            font-size: 20px;
            margin: 0;
            font-weight: 400;
            margin-bottom: 2px;
            a {
              color: #050505;
              text-decoration: none;
            }
          `}
        >
          <OutboundLink target="_blank" href={data["Last_Post_URL"]}>
            <span
              css={css`
                color: #6b6b6b;
              `}
            >
              {index + 1}.{" "}
            </span>
            {data["Last_Post_Title"]}
          </OutboundLink>{" "}
          <OutboundLink
            target="_blank"
            href={data["URL"]}
            css={css`
              font-size: 16px;
              color: #bababa;
            `}
          >
            <span
              css={css`
                font-size: 14px;
                color: #aaa;
              `}
            >
              ({data["URL"]})
            </span>
          </OutboundLink>
        </h2>
      </div>
      <h3
        css={css`
          font-size: 16px;
          font-weight: normal;
          margin: 0;
          margin-top: 7px;
          color: #6b6b6b;
        `}
      >
        Posted on{" "}
        <OutboundLink
          target="_blank"
          href={data["URL"]}
          css={css`
            color: #6b6b6b;
          `}
        >
          {data["Name"]}
        </OutboundLink>{" "}
        {"("}
        {data["Tags"]?.map(tag => (
          <span
            key={tag}
            css={css`
              margin-right: 5px;
              text-decoration: none;

              &:last-child {
                margin-right: 0;
              }
            `}
          >
            <Link
              css={css`
                font-size: 14px;
                color: #6b6b6b;
                transition: color 1s linear;

                &:hover {
                  color: ${`rgb(${getRandomInt(0, 200)},
                 ${getRandomInt(0, 200)},
                 ${getRandomInt(0, 200)})
               })`};
                }
              `}
              to={`/tag/${tag.replace(".", "dot").replace(/ /g, "-")}/posts`}
            >
              #{tagNameToLabel(tag).toLowerCase()}
            </Link>
          </span>
        ))}
        {")"} ({moment(data["Last_Post_Date"]).fromNow()})
      </h3>
    </div>
  )
}

export default ListItem
