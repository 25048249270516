import React from "react"
import { css } from "@emotion/react"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Navbar = () => {
  return (
    <ul
      css={css`
        list-style: none;
        margin: 0;
        padding: 15px 20px;
        background-color: #0178bd;
        li {
          display: inline;
          margin: 0;
          margin-right: 20px;
          font-size: 18px;
        }

        a {
          text-decoration: none;
          color: #fff;
        }
      `}
    >
      <li>
        <Link
          css={css`
            margin-right: 100px;
            font-size: 20px;
          `}
          to="/"
        >
          <strong>Blog Surf 🏄</strong>
        </Link>
      </li>
      <li>
        <Link to="/">home</Link>
      </li>
      <li>
        <Link to="/directory">directory</Link>
      </li>
      <li>
        <Link to="/blogs">blogs</Link>
      </li>
      <li>
        <Link to="/posts">posts</Link>
      </li>
      <li>
        <Link to="/submit">add blog</Link>
      </li>

      {/* <li>
        <Link to="/subscribe">subscribe</Link>
      </li> */}
      {/* <li>
        <Link to="/data">data</Link>
      </li> */}
      <li>
        <Link to="/about">about</Link>
      </li>

      {/* <li>
        <Link to="/books">books</Link>
      </li> */}
      {/* <li>
        <Link to="/miami">miami</Link>
      </li> */}
      {/* <li>
        <OutboundLink href="https://www.patreon.com/blogsurf" target="_blank">
          donate
        </OutboundLink>
      </li> */}
    </ul>
  )
}

export default Navbar
